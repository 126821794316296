import CONSTANTS from 'src/utils/constants';
import GetMECPreValidationDataResult from 'src/models/mec/prevalidation/GetMECPreValidationDataResult';

export interface PreValidationState {
    category: string,
    preValidationResult: GetMECPreValidationDataResult
}

export const initialState: PreValidationState = {
    category: '',
    preValidationResult: {records: []}
}