import {RelatedTable, useTreeCollection} from "@amzn/polaris-related-table";
import {TPTaxReportData} from "src/models/reports/TPTaxReport";
import React from "react";
import ServiceCollection from "src/services/ServiceCollection";
import {TP_TAX_REPORT_FORMATTING_EXCLUSION_SET} from "src/utils/constants";


export default function TPTaxReportTableGrid(props: { data: TPTaxReportData[], services: ServiceCollection }) {

    const {data = [], services} = props;

    function formatAttributeValue(attributeName: string, attributeValue: any): string {
        if (!attributeValue) {
            return attributeValue;
        } else if (TP_TAX_REPORT_FORMATTING_EXCLUSION_SET.has(attributeName)) {
            return attributeValue
        }
        return isNaN(attributeValue) ? attributeValue :
            services.formattingService.formatString(attributeValue, false);
    }

    const columnDefinitions = [
        {
            id: 'attributeName',
            header: 'Attribute Name',
            cell: (item: any) => item.attributeName,
        },
        {
            id: 'attributeValue',
            header: 'Attribute Value',
            cell: (item: any) => formatAttributeValue(item?.attributeName, item?.attributeValue)
        }
    ];

    const {expandNode, items, collectionProps} = useTreeCollection(data, {
        columnDefinitions,
        keyPropertyName: 'attributeName',
        parentKeyPropertyName: 'attributeParentName',
        sorting: {},
    });

    // package used: PolarisRelatedTableComponent, for showing hierarchical tables
    return (
        <RelatedTable
            {...collectionProps}
            expandChildren={expandNode}
            trackBy='attributeName'
            columnDefinitions={columnDefinitions}
            items={items}
        />
    )
}