import { initialState, ReportsState } from "src/services/reports/ReportsState";
import React from "react";
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import { reportsReducer } from "src/services/reports/ReportsReducer";
import TPEAction from "src/models/common/TPEAction";
import ServiceCollection from "src/services/ServiceCollection";
import { Box } from "@amzn/awsui-components-react";
import { useHistory, useParams } from "react-router-dom";

export type ContextType = {
    state: ReportsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const TPTaxCwbReportProvider = (props: {
    state: ReportsState, dispatch: React.Dispatch<TPEAction>, services: ServiceCollection,
    children: JSX.Element
}) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <TPTaxCwbReportContext.Provider value={providerValue}>
            {children}
        </TPTaxCwbReportContext.Provider>
    );
}

export const TPTaxCwbReportContext = React.createContext(null as ContextType | null);

export default function TPTaxCwbReportView(props: { services: ServiceCollection }) {
    const { services } = props;
    const history = useHistory();
    const { filter } = useParams<{ filter: string }>();
    const [state, dispatch] = useReducerWithLogger(reportsReducer, initialState);
    return (
        <TPTaxCwbReportProvider state={state} dispatch={dispatch} services={services}>
            <Box variant="awsui-key-label" textAlign="center">Following Page is currently under development !!!</Box>
        </TPTaxCwbReportProvider>
    );
}
