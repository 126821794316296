import {
    Box,
    Button,
    ColumnLayout,
    Container,
    Select,
    SpaceBetween,
    StatusIndicator,
    TextFilter
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import TPEAction from 'src/models/common/TPEAction';
import ServiceCollection from 'src/services/ServiceCollection';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import CONSTANTS from 'src/utils/constants';
import { TPELoadingSpinner } from '../../shared/TPELoadingSpinner';
import PreValidationGrid from './PreValidationGrid';
import { PreValidationState, initialState } from 'src/services/mec/prevalidation/PreValidationState';
import { preValidationReducer, ACTIONS } from 'src/services/mec/prevalidation/PreValidationReducer';
import StringUtils from 'src/utils/stringUtils';
import '../MEC.scss';
import { DownloadReportRequest } from "src/models/reports/DownloadReport";
import { GenerateReportRequest } from "src/models/reports/GenerateReport";
import useLocalStorage from "src/utils/useLocalStorage";
import { FetchReportRequest } from "src/models/reports/FetchReport";
import TPEErrorWatcher from "src/components/shared/TPEErrorWatcher";
import { getPermissions } from 'src/components/AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';

export type ContextType = {
    state: PreValidationState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const PreValidationProvider = (props: any) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <PreValidationContext.Provider value={providerValue}>
            {children}
        </PreValidationContext.Provider>
    );
}
export const PreValidationContext = React.createContext(null as unknown as ContextType);

export default function RecordsView(props: {services: ServiceCollection}) {
    const { services } = props;
    const [state, dispatch] = useReducerWithLogger(preValidationReducer, initialState);
    const { canRunPrevalidation } = getPermissions(AppModules.MEC);

    const [enteredCloseDate, setEnteredCloseDate] = useState('');
    const [selectedCategory, setSelectedCategory] = useState({value: '', label: ''});
    const [closeDate, setCloseDate] = useState('');
    const [searchText, setSearchText] = useState('');
    const [syncingReportData, setSyncingReportData] = useState(false);

    const [downloadReportRequestPayload, setDownloadReportRequestPayload] = React.useState(null as unknown as DownloadReportRequest);
    const [selectedDetailTabReportId, setSelectedDetailTabReportId] = useState(null as unknown as string);
    const [syncReportRequest, setSyncReportRequest] = React.useState(null as unknown as FetchReportRequest);
    const [reportData, isReportDataLoading, reportFetchError] = services.reportsService.fetchReportData(syncReportRequest);
    const [generateReportRequest, setGenerateReportRequest] = React.useState(null as unknown as GenerateReportRequest);
    const [generateReportResponse, isGeneratingReport, reportGenerationError] = services.reportsService.generateReport(generateReportRequest);
    const [downloadReportUrl, downloadReportLoading, downloadReportError] = services.reportsService.downloadReport(downloadReportRequestPayload);
    const [isDownloadDisabled, setDownloadDisabled] = useState(true);

    const searchCLIs = (searchText:string) => {

    }

    const sleep = (ms: number) => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        if (!reportData) {
            setDownloadDisabled(true);
            return;
        } else if (reportData.payload == null) {
            setDownloadDisabled(true);
            sleep(5000).then(() => setSyncReportRequest({
                reportName: reportData.reportName,
                params: {}
            }));
            return;
        }
        dispatch(ACTIONS.SET_PREVALIDATION_RESULT.withPayload(reportData.payload));
        setSelectedDetailTabReportId(reportData.reportId);
        setDownloadDisabled(false);
        setSyncingReportData(false);
    }, [reportData]);

    useEffect(() => {
        if (generateReportResponse) {
            setSyncingReportData(true);
            setSyncReportRequest({
                reportName: getReportName() || '',
                params: {}
            });
        }
    }, [generateReportResponse]);

    useEffect(() => {
        const reportName = getReportName()
        if (reportName) {
            setSyncReportRequest({
                reportName: reportName,
                params: {}
            })
            dispatch(ACTIONS.SET_CATEGORY.withPayload(selectedCategory?.value));
        } else {
            setDownloadDisabled(true);
        }
    }, [selectedCategory])

    useEffect(() => {
        if(downloadReportError) {
            services.messageService.showErrorBanner(downloadReportError);
        }
        else if (downloadReportUrl) {
            window.open(downloadReportUrl);
        }
    }, [downloadReportUrl, downloadReportError])

    const setPreValidationParameters = () => {
        setCloseDate(enteredCloseDate);
        updateGenerateReportRequest();
    }

    const getReportName = () => {
        const categories = CONSTANTS.MEC_PREVALIDATION_CATEGORIES;
        if (selectedCategory.value == categories.COA_CHANGES) {
            return CONSTANTS.REPORT_TYPES.PRE_VALIDATION_COA;
        } else if (selectedCategory.value == categories.CLI_CDT_DATA) {
            return CONSTANTS.REPORT_TYPES.PRE_VALIDATION_CDT;
        } else if (selectedCategory.value == categories.CLI_PTA_DATA) {
            return CONSTANTS.REPORT_TYPES.PRE_VALIDATION_PTA;
        } else {
            return null;
        }
    }

    const updateGenerateReportRequest = () => {
        const reportName = getReportName();
        if (reportName) {
            setGenerateReportRequest({
                userId: useLocalStorage.getUsernameFromLocalStorage(),
                reportName: reportName,
                params: {}
            });
        }
    }

    return <PreValidationProvider services={services} state={state} dispatch={dispatch}>
        <div className="customTableTableHeader">
            <Box variant="p">Pre-validation category</Box>
            <SpaceBetween direction="horizontal" size="m">
                <Select
                    className="mecCategorySelector"
                    onChange={(e: any) => setSelectedCategory(e.detail.selectedOption)}
                    options={CONSTANTS.MEC_PREVALIDATION_CATEGORIES_LIST}
                    selectedOption={selectedCategory}
                    disabled={syncingReportData}
                />
                { canRunPrevalidation && <Button
                    variant="primary"
                    disabled={syncingReportData || getReportName() == null || StringUtils.isNullOrEmpty(selectedCategory.value)}
                    onClick={() => setPreValidationParameters()}
                >
                    {syncingReportData ? 'Running...' : 'Run'}
                </Button> }
            </SpaceBetween>
        </div>
        <Container data-class="containerWithHeaderAndGrid">
            <SpaceBetween direction="vertical" size="m">
                <div className="customTableTableHeader">
                    <ColumnLayout columns={2} data-class="fullColumnLayout">
                        <SpaceBetween direction="horizontal" size="m">
                            <Box variant="h2">Pre-validation</Box>
                            {
                                !isDownloadDisabled && !isReportDataLoading && reportData && reportData.createdAt &&
                                    <Box variant="p"> - last generated {reportData.createdAt}</Box>
                            }
                        </SpaceBetween>
                        <Box float="right">
                            <Button variant="normal" disabled={syncingReportData || isDownloadDisabled}
                            onClick={() =>
                                setDownloadReportRequestPayload({
                                reportId: selectedDetailTabReportId,
                                userId: useLocalStorage.getUsernameFromLocalStorage()
                            })
                            }>{downloadReportLoading ? 'Downloading...' : 'Download Report' }</Button>
                        </Box>
                        <TextFilter data-class="basicTextFilter"
                            filteringText={searchText}
                            onDelayedChange={({ detail }) => searchCLIs(detail.filteringText)}
                            onChange={({ detail }) => setSearchText(detail.filteringText)}
                            filteringPlaceholder='Search CLI'
                            filteringAriaLabel='Search CLI'
                            countText='0 matches found'
                        />
                    </ColumnLayout>
                </div>
                { getReportName() == null || reportData == null ?
                    <Box textAlign="center" padding="l" className="tpeTableEmptyMessage">
                        <StatusIndicator type="info">{'No data to display'}</StatusIndicator>
                    </Box>
                :
                    <TPELoadingSpinner loading={syncingReportData || isReportDataLoading} loadingText={syncingReportData ? 'Running Pre-validation job' : 'Loading Pre-validation data'}>
                        <div className="tableContainer">
                            <PreValidationGrid loading={syncingReportData}/>
                        </div>
                    </TPELoadingSpinner>
                }
            </SpaceBetween>

            <TPEErrorWatcher services={services} errors={[reportFetchError]}/>
        </Container>
    </PreValidationProvider>
}