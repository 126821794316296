import React from 'react';
import { useEffect, useState, useMemo } from "react";
import 'src/assets/styles/react-table.scss';
import 'src/components/CustomAppLayout.scss';
import ServiceCollection from "src/services/ServiceCollection";
import { TPReportsSummaryRequest } from "src/models/reports/TPReportsSummary";
import { SelectProps } from '@amzn/awsui-components-react/polaris';
import TPPieChart from '../../shared/TPPieChart';
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    SpaceBetween,
    Grid,
    Header,
} from '@amzn/awsui-components-react';
import ReportMetricsDashboardFilterSelector from './ReportMetricsDashboardFilterSelector';
import { generatePieChartData } from './util';

type ComponentProps = {
    services: ServiceCollection
}

export default function ReportMetricsDashboard(props: ComponentProps) {

    const { services } = props;
    const [tpVarianceDetails, setTpVarianceDetails] = useState<Map<string, number>>(new Map());
    const [reportingCLIExecutionStatusDetails, setReportingCLIExecutionStatusDetails] = useState<Map<string, number>>(new Map());
    const [mecCliExecutionStatusDetails, setMecCliExecutionStatusDetails] = useState<Map<string, number>>(new Map());
    const [totalCli, setTotalCli] = useState(0);
    const [selectedPeriodNameOption, setSelectedPeriodNameOption] = React.useState(null as unknown as SelectProps.Option | null);
    const [selectedAccountingDayOption, setSelectedAccountingDayOption] = React.useState(null as unknown as SelectProps.Option | null);
    const [tpReportsSummaryRequest, setTpReportsSummaryRequest] = React.useState(null as unknown as TPReportsSummaryRequest);
    const [fetchTPReportSummaryResponse, fetchTpReportsSummaryLoading, tpReportsSummaryFetchError] = services.reportsService.fetchCompleteTPReportsSummary(tpReportsSummaryRequest);

    useEffect(() => {
        if (!tpReportsSummaryFetchError) {
            return;
        }
        services.messageService.showErrorAutoDismissBanner(tpReportsSummaryFetchError);
    }, [tpReportsSummaryFetchError]);

    useEffect(() => {
        if (fetchTPReportSummaryResponse.VarianceAnalysis) {
            setTpVarianceDetails(fetchTPReportSummaryResponse.VarianceAnalysis);
        }
        if (fetchTPReportSummaryResponse.ReportingServiceStatus) {
            setReportingCLIExecutionStatusDetails(fetchTPReportSummaryResponse.ReportingServiceStatus);
        }
        if (fetchTPReportSummaryResponse.AtpCliStatus) {
            setMecCliExecutionStatusDetails(fetchTPReportSummaryResponse.AtpCliStatus);
            const sum = Array.from(fetchTPReportSummaryResponse.AtpCliStatus.values()).reduce((acc, value) => acc + value, 0);
            setTotalCli(sum);
        }
    }, [fetchTPReportSummaryResponse]);

    const fetchTPTaxReportMetricsData = () => {
        if (!!selectedAccountingDayOption && !!selectedPeriodNameOption) {
            setTpReportsSummaryRequest({
                period: selectedPeriodNameOption?.value as string,
                accountingDay: selectedAccountingDayOption?.value as string
            })
        }
    }

    const reportingServiceStatusData = useMemo(() => generatePieChartData(reportingCLIExecutionStatusDetails, totalCli), [reportingCLIExecutionStatusDetails, totalCli]);
    const varianceAnalysisData = useMemo(() => generatePieChartData(tpVarianceDetails, totalCli), [tpVarianceDetails, totalCli]);
    const atpCliStatusData = useMemo(() => generatePieChartData(mecCliExecutionStatusDetails, totalCli), [mecCliExecutionStatusDetails, totalCli]);


    return (
        <Container>
            <SpaceBetween direction="vertical" size="m">
                <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                    <ReportMetricsDashboardFilterSelector
                        selectedAccountingPeriod={selectedPeriodNameOption}
                        selectedAccountingDay={selectedAccountingDayOption}
                        services={services}
                        onAccountingPeriodSelected={(periodOption) => setSelectedPeriodNameOption({
                            label: periodOption?.label,
                            value: periodOption?.value
                        })}
                        onAccountingDaySelected={(accountingDayOption) => setSelectedAccountingDayOption({
                            label: accountingDayOption?.label,
                            value: accountingDayOption?.value
                        })}
                    />
                    <Box float="left" padding={{ top: "xl" }}>
                        <SpaceBetween direction="horizontal" size="l">
                            <Button
                                onClick={fetchTPTaxReportMetricsData}
                                disabled={(!selectedAccountingDayOption || !selectedPeriodNameOption)}
                                loading={fetchTpReportsSummaryLoading}
                                variant="primary"
                            >
                                View Report
                            </Button>
                        </SpaceBetween>
                    </Box>
                </Grid>
                <Grid gridDefinition={[{ colspan: { default: 7, xl: 8 } }, { colspan: { default: 5, xl: 4 } }]}>
                    <Container header={
                        <Header variant="h2"
                            description="CLI Posting data from the selected period">
                            Reporting Service Status
                        </Header>}>
                        {reportingCLIExecutionStatusDetails && reportingCLIExecutionStatusDetails.size > 0 ? (
                            <ColumnLayout columns={4} variant="text-grid">
                                {Array.from(reportingCLIExecutionStatusDetails.entries()).map(([label, value], index) => (
                                    <div key={index}>
                                        <Box variant="awsui-key-label">{label}</Box>
                                        <h1>{value}</h1>
                                    </div>
                                ))}
                            </ColumnLayout>
                        ) : (
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    No reporting status details available to display
                                </Box>
                            </Box>
                        )}
                    </Container>
                    <TPPieChart
                        data={reportingServiceStatusData}
                        totalUnits={totalCli}
                        ariaDescription="Donut chart showing ATP reporting dashboard data."
                        ariaLabel="ATP reporting Donut chart"
                        emptyDataDescription='No report status data available to display on the chart'
                        noMatchDataDescription='No report status data available for the selected filter criteria'
                    />
                </Grid>
                <Grid gridDefinition={[{ colspan: { default: 7, xl: 8 } }, { colspan: { default: 5, xl: 4 } }]}>
                    <Container header={
                        <Header variant="h2" description="Variance data from the selected period">
                            Variance Analysis
                        </Header>}>
                        {tpVarianceDetails && tpVarianceDetails.size > 0 ? (
                            <ColumnLayout columns={4} variant="text-grid">
                                {Array.from(tpVarianceDetails.entries()).map(([label, value], index) => (
                                    <div key={index}>
                                        <Box variant="awsui-key-label">{label}</Box>
                                        <h1>{value}</h1>
                                    </div>
                                ))}
                            </ColumnLayout>
                        ) : (
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    No Variance data available to display
                                </Box>
                            </Box>
                        )}
                    </Container>
                    <div>
                        <TPPieChart
                            data={varianceAnalysisData}
                            totalUnits={totalCli}
                            ariaDescription="Donut chart showing ATP reporting dashboard data."
                            ariaLabel="ATP reporting Donut chart"
                            emptyDataDescription='No variance data available to display on the chart'
                            noMatchDataDescription='No variance data available for the selected filter criteria'
                        />
                    </div>
                </Grid>
                <Grid gridDefinition={[{ colspan: { default: 7, xl: 8 } }, { colspan: { default: 5, xl: 4 } }]}>
                    <Container header={
                        <Header variant="h2" description="Month end close data from the selected period">
                            Month End Close Status
                        </Header>}>
                        {mecCliExecutionStatusDetails && mecCliExecutionStatusDetails.size > 0 ? (
                            <ColumnLayout columns={4} variant="text-grid">
                                {Array.from(mecCliExecutionStatusDetails.entries()).map(([label, value], index) => (
                                    <div key={index}>
                                        <Box variant="awsui-key-label">{label}</Box>
                                        <h1>{value}</h1>
                                    </div>
                                ))}
                            </ColumnLayout>
                        ) : (
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    No MEC CLI Execution Summary data available to display
                                </Box>
                            </Box>
                        )}
                    </Container>
                    <div>
                        <TPPieChart
                            data={atpCliStatusData}
                            totalUnits={totalCli}
                            ariaDescription="Donut chart showing ATP reporting dashboard data."
                            ariaLabel="ATP reporting Donut chart"
                            emptyDataDescription='No month end close data available to display on the chart'
                            noMatchDataDescription='No month end close data available for the selected filter criteria'
                        />
                    </div>
                </Grid>
            </SpaceBetween>
        </Container>
    )

};