import TPEAction from "src/models/common/TPEAction";
import { PreValidationState } from "src/services/mec/prevalidation/PreValidationState";

export const ACTIONS = Object.freeze({
    SET_CATEGORY: new TPEAction('SET_CATEGORY'),
    SET_PREVALIDATION_RESULT: new TPEAction('SET_PREVALIDATION_RESULT')
});

export function preValidationReducer(state: PreValidationState, action: TPEAction) : PreValidationState {
    switch(action.type) {
        case ACTIONS.SET_CATEGORY.type:
            return {
                ...state,
                category: action.payload,
            };
        case ACTIONS.SET_PREVALIDATION_RESULT.type:
            return {
                ...state,
                preValidationResult: {records: JSON.parse(action.payload)},
            };
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}