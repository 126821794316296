import React, { useEffect } from 'react';

import { useHistory } from "react-router-dom";
import { Box, Button, Container, Header } from "@amzn/awsui-components-react/polaris";
import { DashboardAction } from 'src/models/dashboard';
import Chart from 'chart.js';
import pattern from 'patternomaly';
import { getBarchartSettings, getRandomIntInclusive, thousandFormatter, updateBarchart } from './TPEChart'


type ComponentProps = {
    selectedQuarter: any
    dispatch: React.Dispatch<DashboardAction>
}

// Builds the barchart
function buildChart(chartCanvasRef: any, barchartRef: any, valuesSumRef: any) {

    if (chartCanvasRef == null || chartCanvasRef.current == null) {
        return;
    }

    const myChartRef = chartCanvasRef.current.getContext("2d");
    const dataPoints = [
        getRandomIntInclusive(100, 1200),
        getRandomIntInclusive(100, 1200),
        getRandomIntInclusive(100, 1200),
        getRandomIntInclusive(100, 1200),
        getRandomIntInclusive(100, 1200)
    ];
    valuesSumRef.current = dataPoints.reduce((a, b) => a + b, 0);
    const percentages = dataPoints.map(x => 100 / valuesSumRef.current * x);

    if (barchartRef.current) {
        updateBarchart(barchartRef.current, dataPoints);
    }
    else {
        barchartRef.current = new Chart(
            myChartRef,
            getBarchartSettings(
                dataPoints,
                [
                    "Recently updated\nTP agreements",
                    "TP agreements pending\ntax approval",
                    "TP agreements pending\naccounting approvals",
                    "Activated TP\nagreements",
                    "De-activated TP\nagreements"],
                [
                    '#e0e0e0',
                    pattern.draw('diagonal', '#a0a0a0'),
                    pattern.draw('line', '#cccccc'),
                    pattern.draw('dot', '#d0d0d0'),
                    pattern.draw('diagonal-right-left', '#aaaaaa'),
                ],
                ["#9f9f9f", "#9f9f9f", "#9f9f9f", "#9f9f9f", "#9f9f9f"],
                (value, context) => {
                    return `${thousandFormatter(Math.round(value))} (${Math.round(percentages[context.dataIndex])}%)`;
                }
            ));
    }
}


function AgreementsOverview(props: ComponentProps) {
    const chartCanvasRef: any = React.createRef();
    const { selectedQuarter } = props;
    const barChartRef = React.useRef<Chart>();
    const valuesSumRef = React.useRef(0);


    useEffect(() => {
        buildChart(chartCanvasRef, barChartRef, valuesSumRef);
    }, [selectedQuarter])

    return (
        <Container
            header={
                <Header
                    variant="h3"
                >
                    <small>TP Agreement Overview</small>
                </Header>
            }
            footer={<Button className="dasboard-chart-footer-button" variant="link">View my TP agreements for {selectedQuarter.label} ({thousandFormatter(valuesSumRef.current)})</Button>}
        >
            <Box textAlign="center" className="chart-container" padding="xxl">
                <canvas id="agreementChart" height="50" ref={chartCanvasRef}></canvas>
                {/* <img src={image} /> */}
            </Box>
        </Container>
    );

}

export default AgreementsOverview;