import ServiceCollection from "src/services/ServiceCollection";
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import {ACTIONS, reportsReducer} from "src/services/reports/ReportsReducer";
import {Box, Button, ColumnLayout, SpaceBetween, Container} from "@amzn/awsui-components-react";
import {initialState, ReportsState} from "src/services/reports/ReportsState";
import React, {useEffect, useState} from "react";
import TPEAction from "src/models/common/TPEAction";
import 'src/assets/styles/react-table.scss';
import '../reports/ReportsTable.scss';
import ReportingHistoryTableGrid from "src/components/reports/ReportingHistoryTableGrid";
import {ReportSummary} from "src/models/reports/ListReportResponse";
import {DownloadReportRequest} from "src/models/reports/DownloadReport";
import CONSTANTS from "src/utils/constants";
import Username from "src/utils/useLocalStorage";
import useLocalStorage from "src/utils/useLocalStorage";
import {sortRecordsOnDate} from "src/utils/ReportUtils";
import StringUtils from "src/utils/stringUtils";

export type ContextType = {
    state: ReportsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const ReportHistoryProvider = (props: {state: ReportsState, dispatch: React.Dispatch<TPEAction>, services: ServiceCollection, children: JSX.Element}) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <ReportHistoryContext.Provider value={providerValue}>
            {children}
        </ReportHistoryContext.Provider>
    );
}

export const ReportHistoryContext = React.createContext(null as unknown as ContextType);

export default function ReportingHistoryTableView(props: { services: ServiceCollection }){
    const { services } = props;
    const [state, dispatch] = useReducerWithLogger(reportsReducer, initialState);
    const { searchResult } = state;
    const [onRowSelect, setOnRowSelect] = useState(null as unknown as boolean);
    const [reportId, setReportId] = useState(null as unknown as string);
    const [refresh, setRefresh] = useState(true);
    const [reportStatus, setReportStatus] = useState(null as unknown as string);
    const [fetchReportHistoryResponse, fetchReportHistoryLoading, fetchReportHistoryError] = services.reportsService.fetchReportHistory(useLocalStorage.getUsernameFromLocalStorage(), refresh);
    const [downloadReportRequestPayload, setDownloadReportRequestPayload] = useState(null as unknown as DownloadReportRequest);
    const [downloadReportUrl, downloadReportLoading, downloadReportError] = services.reportsService.downloadReport(downloadReportRequestPayload);

    useEffect(() => {
        if (!fetchReportHistoryResponse) {
            return;
        }
        setRefresh(false);
        sortRecordsOnDate(fetchReportHistoryResponse.ReportSummaryList, "createdAt");
        dispatch(ACTIONS.SET_SEARCH_RESULT.withPayload(fetchReportHistoryResponse));
    }, [fetchReportHistoryResponse])

    useEffect(() => {
        if (!downloadReportError) {
            return;
        }
        services.messageService.showErrorBanner(downloadReportError);
    }, [downloadReportError])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(downloadReportUrl)) {
            return;
        }
        window.open(downloadReportUrl);
    }, [downloadReportUrl])

    const onRowClicked = (isChecked: boolean, reportSummaryList: ReportSummary) => {
        setOnRowSelect(isChecked)
        if (isChecked) {
            setReportId(reportSummaryList.reportId);
            setReportStatus(reportSummaryList.reportStatus);
        }
    }

    const onDownloadClicked = () => {
        if (!!reportId) {
            setDownloadReportRequestPayload({
                userId: useLocalStorage.getUsernameFromLocalStorage(),
                reportId: reportId
            })
        }
    }

    return (
        <ReportHistoryProvider services={services} state={state} dispatch={dispatch}>
            <Container className="polaris-content-container">
                <div>
                    <div className="reportsTableHeader">
                        <ColumnLayout columns={2} data-class="fullColumnLayout">
                            <Box variant="h2"><span>ATP Reports History </span></Box>
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="m">
                                    <Button className={fetchReportHistoryLoading ? "" : "blueIcon"} disabled={fetchReportHistoryLoading} onClick={() => setRefresh(true)} iconName="refresh" variant="icon" />
                                    <Button
                                        className={"downloadReportButton"}
                                        onClick={onDownloadClicked}
                                        disabled = { (!onRowSelect) || downloadReportLoading || (reportStatus != CONSTANTS.REPORT_STATUS.SUCCESS)}
                                        variant="primary"
                                    >
                                        {downloadReportLoading ? 'Downloading...' : 'Download' }
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        </ColumnLayout>
                    </div>
                    <div className="tableContainer">
                        <ReportingHistoryTableGrid
                            key="reportHistoryTablesGrid"
                            loading={fetchReportHistoryLoading}
                            reportTables={searchResult?.ReportSummaryList || []}
                            onRowClicked = {onRowClicked}
                        />
                    </div>
                </div>
            </Container>
        </ReportHistoryProvider>
    );
}