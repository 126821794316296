import React, {  } from 'react';
import { ExpandableSection, Header, Box } from '@amzn/awsui-components-react/polaris';
import { TPELoadingSpinner } from "src/components/shared/TPELoadingSpinner";
import ICRegistration from '../../models/icrs/ICRegistration';

function ValueWithLabel(props: any) {
    const { label } = props;
    return (
        <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
                {label}
            </Box>
            <div>{props.children}</div>
        </div>
    );
}

export default function ICRSRegistrationDetails(props: { registration: ICRegistration, expanded?: boolean, loading: boolean }) {
    const { registration, expanded = false, loading } = props;
    
    return (
        <ExpandableSection className="polarisExpandableSection agreementDetailsSection" variant="container"
            defaultExpanded={expanded}
            header={
                <Header>
                    <h2>Step 1: ICRS Registration details - ({registration.registrationNumber})</h2>
                </Header>
            }
        >
            <div className="calculationContentContainer">
                <TPELoadingSpinner loading={loading}>
                    <React.Fragment>
                        <div className="grid-item"><ValueWithLabel label="Registration Number">{registration.registrationNumber}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Created Date">{new Date(registration.createDateTime || "").toDateString()}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Created By">{registration.createdBy}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Last Updated date">{new Date(registration.lastUpdatedDateTime || "").toDateString()}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Updated By">{registration.lastUpdatedBy}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Accounting Output (BEAT)">{registration.beat}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Export of Services">{registration.exportOfServices? "True" : "False"}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Provider">{registration.providerEntityName}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Recipient">{registration.recipientEntityName}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Provider Co. Code">{registration.providerCompanyCode}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Recipient Co. Code">{registration.recipientCompanyCode}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Provider Tax Reg Cons.">{registration.providerTaxRegConsideration}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Recipient Tax Reg Cons.">{registration.recipientTaxRegConsideration}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Jurisdiction (1st Party)">{registration.providerJurisdiction}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Jurisdiction (2nd Party)">{registration.recipientJurisdiction}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Workbook Reviewer">{registration.accountingOwner}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Accouting approver">{registration.accountingApprover}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Effective From Date">{new Date(registration.effectiveFromDate || "").toDateString()}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Tax Product Category ID">{registration.taxProductCategoryId}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Tax  Product Category Name">{registration.taxProductCategoryName}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Tax Product ID">{registration.taxProductId}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Tax Product Name">{registration.taxProductName}</ValueWithLabel></div>
                        <div className="grid-item-2"><ValueWithLabel label="Description">{registration.description}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Workbook Name">{registration.workbookName}</ValueWithLabel></div>
                    </React.Fragment>
                </TPELoadingSpinner>
            </div>
        </ExpandableSection>
    )
}
