import React, { useEffect } from 'react';

import { useHistory } from "react-router-dom";
import { Box, Button, Container, Header } from "@amzn/awsui-components-react/polaris";
import image from 'src/images/calcs_overview.png';
import Chart from 'chart.js';
import pattern from 'patternomaly';
import { getBarchartSettings, getRandomIntInclusive, thousandFormatter, updateBarchart } from './TPEChart'

type ComponentProps = {
    selectedQuarter: any
}

// Builds the barchart
function buildChart(chartCanvasRef: any, barchartRef: any, valuesSumRef: any) {

    if (chartCanvasRef == null || chartCanvasRef.current == null) {
        return;
    }

    const myChartRef = chartCanvasRef.current.getContext("2d");
    const dataPoints = [
        getRandomIntInclusive(100, 1200),
        getRandomIntInclusive(100, 1200),
        getRandomIntInclusive(100, 1200),
        getRandomIntInclusive(100, 1200),
        getRandomIntInclusive(100, 1200),
        getRandomIntInclusive(100, 1200)
    ];
    valuesSumRef.current = dataPoints.reduce((a, b) => a + b, 0);
    const percentages = dataPoints.map(x => 100 / valuesSumRef.current * x);

    if (barchartRef.current) {
        updateBarchart(barchartRef.current, dataPoints);
    }
    else {
        barchartRef.current = new Chart(
            myChartRef,
            getBarchartSettings(
                dataPoints,
                [
                    "Pending automation\n- draft agreements",
                    "Pending automation\nactivated\nagreements",
                    "TP calc setup\nin progress",
                    "TP calc\nin review",
                    "Activated calcs",
                    "Termindated calcs"
                ],
                [
                    '#e0e0e0',
                    pattern.draw('diagonal', '#a0a0a0'),
                    pattern.draw('line', '#cccccc'),
                    pattern.draw('dot', '#d0d0d0'),
                    pattern.draw('diagonal-right-left', '#aaaaaa'),
                    pattern.draw('line-vertical', '#e0e0e0'),

                ],
                ["#9f9f9f", "#9f9f9f", "#9f9f9f", "#9f9f9f", "#9f9f9f", "#9f9f9f"],
                (value, context) => {
                    return `${thousandFormatter(Math.round(value))} (${Math.round(percentages[context.dataIndex])}%)`;
                }
            ));
    }
}

function CalcOverview(props: ComponentProps) {
    const { selectedQuarter } = props;
    const chartCanvasRef: any = React.createRef();
    const barChartRef = React.useRef<Chart>();
    const valuesSumRef = React.useRef(0);


    useEffect(() => {
        buildChart(chartCanvasRef, barChartRef, valuesSumRef);
    }, [selectedQuarter])

    let history = useHistory();

    const handleViewTPCalcsClicked = () => {
        history.push("/calc-builder-1");
    }


    return (
        <Container
            header={
                <Header variant="h3">
                    <small>TP Calcs Overview</small>
                </Header>
            }
            footer={
                <Button
                    className="dasboard-chart-footer-button"
                    variant="link"
                    onClick={handleViewTPCalcsClicked}>
                    View my TP calcs for {selectedQuarter.label} ({thousandFormatter(valuesSumRef.current)})
                </Button>
            }
        >
            <Box textAlign="center" className="chart-container">
                <canvas id="agreementChart" height="50" ref={chartCanvasRef}></canvas>
            </Box>
        </Container>
    );

}

export default CalcOverview;