import { UserProfile } from 'src/models/users/UserProfile';
import DynamicBreadcrumb from '../../models/navigation/DynamicBreadcrumb';

export interface GlobalState {
    dynamicBreadcrumb?: DynamicBreadcrumb;
    selectedWorklistsTab?: string;
    error?: string;
    bannerItems: Array<any>;
    isSimulateMode: boolean;
    showSideBar: boolean;
    pageSidebarKey?: string;
    simulatedUserProfile?: UserProfile;
}

export const initialState: GlobalState = {
    bannerItems: [],
    isSimulateMode: false,
    showSideBar: false
}