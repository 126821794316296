import { UserProfile } from 'src/models/users/UserProfile';
import TPEAction from '../../models/common/TPEAction'
import { GlobalState } from './GlobalState';


/**
 * List here the actions supported by this reducer
 */
export const GLOBAL_ACTIONS = Object.freeze({
    SET_DYNAMIC_BREADCRUMB: new TPEAction('SET_DYNAMIC_BREADCRUMB'),
    SET_SELECTED_WORKLIST_TAB: new TPEAction('SET_SELECTED_WORKLIST_TAB'),
    SHOW_SIDEBAR: new TPEAction('SHOW_SIDEBAR'),
    HIDE_SIDEBAR: new TPEAction('HIDE_SIDEBAR'),
    ERROR: new TPEAction('error'),
    ADD_BANNER_MESSAGE: new TPEAction('ADD_BANNER_MESSAGE'),
    REMOVE_BANNER_MESSAGE: new TPEAction('REMOVE_BANNER_MESSAGE'),
    CLEAR_ALL_BANNER_MESSAGES: new TPEAction('CLEAR_ALL_BANNER_MESSAGES'),
    START_SIMULATION: new TPEAction('START_SIMULATION'),
    END_SIMULATION: new TPEAction('END_SIMULATION')
});

/**
* This function is responsible for updating the state based on action type
* @param state The current global state
* @param action The current dispatched action
*/
export function globalReducer(state: GlobalState, action: TPEAction) : GlobalState {
    switch (action.type) {
        case GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.type: {
            return {
                ...state,
                error: '',
                dynamicBreadcrumb: action.payload,
            };
        }
        case GLOBAL_ACTIONS.SHOW_SIDEBAR.type: {
            return {
                ...state,
                pageSidebarKey: action.payload,
                showSideBar: true,
            };
        }
        case GLOBAL_ACTIONS.HIDE_SIDEBAR.type: {
            return {
                ...state,
                pageSidebarKey: action.payload,
                showSideBar: false,
            };
        }
        case GLOBAL_ACTIONS.SET_SELECTED_WORKLIST_TAB.type: {
            return {
                ...state,
                error: '',
                selectedWorklistsTab: action.payload,
            };
        }
        case GLOBAL_ACTIONS.ERROR.type: {
            return {
                ...state,
                error: action.payload,
            };
        }
        case GLOBAL_ACTIONS.ADD_BANNER_MESSAGE.type: {
            const { bannerItems } = state;
            bannerItems.push(action.payload);
            console.log("Added banner item: ", action.payload);
            console.table(bannerItems);
            return {
                ...state,
                bannerItems: [...bannerItems],
            };
        }
        case GLOBAL_ACTIONS.REMOVE_BANNER_MESSAGE.type: {
            if (action.payload == null){
                return {
                    ...state,
                    bannerItems: [],
                };
            }
            const { bannerItems } = state;
            return {
                ...state,
                bannerItems: [...bannerItems.filter((x: { id: any; }) => x.id !== action.payload)],
            };
        }
        case GLOBAL_ACTIONS.CLEAR_ALL_BANNER_MESSAGES.type: {
            return {
                ...state,
                bannerItems: [],
            };
        }
        case GLOBAL_ACTIONS.START_SIMULATION.type: {
            return {
                ...state,
                simulatedUserProfile: action.payload,
                isSimulateMode: true
            }
        }
        case GLOBAL_ACTIONS.END_SIMULATION.type: {
            return {
                ...state,
                simulatedUserProfile: undefined,
                isSimulateMode: false
            }
        }
        
        default:
            return state;
    }
}