export default class TPEAction {
    type: string;
    payload: any;
    fieldName: string = '';

    constructor(type: string) {
        this.type = type;
    }

    withPayload(payload: any): TPEAction {
        this.payload = payload;
        return this;
    }

    withFieldName(fieldName: string): TPEAction {
        this.fieldName = fieldName;
        return this;
    }
}