export function generatePieChartData(items: Map<string, number>, totalCli: number) {
  // Convert the map entries to an array and iterate over them
  return Array.from(items.entries()).map(([label, value]) => ({
    title: label,
    percentage: calculatePercentage(value, totalCli),
    value: value,
  }));
}

function calculatePercentage(value: number | undefined, total: number): number {
  if (value !== undefined) {
    return (value / total) * 100;
  }
  return 0;
}