import React, { useEffect, useReducer, useContext } from 'react';
import { Grid, SpaceBetween, Container, Header, Select, Alert, Box, Spinner } from '@amzn/awsui-components-react/polaris';
import AgreementsOverview from './AgreementsOverview';
import CalcsOverview from './CalcsOverview';
import EngineRuntime from './EngineRuntime';
import DashboardService from 'src/services/dashboard/DashboardService';
import { initialState, DashboardNotification } from 'src/models/dashboard';
import { dashboardReducer, ACTIONS } from 'src/services/dashboard/DashboardReducer';
import './Dashboard.scss';
import { DashboardContext } from 'src/components/App';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';




export default function DashboardView() {
    const [state, dispatch] = useReducerWithLogger(dashboardReducer, initialState);
    const { notifications, quarters, quarter, isLoading, engineRuntimeBatches, engineRuntimeSelectedBatch, error, } = state;
    const service: DashboardService | undefined = useContext(DashboardContext);


    // Equivalent to componentDidMount
    useEffect(() => {
        (async function loadNotifications() {
            try {
                if (!service) {
                    return;
                }
                const results = await (await service.loadData()).json();
                /* Temporarily mapping to match notification shape */
                const notifications = results.map(
                    ((x: { title: any; id: any }) => ({ id: x.id, text: x.title, date: new Date().toDateString() }))
                );
                // Updating notifications in the state
                dispatch(ACTIONS.SET_NOTIFICATIONS
                    .withPayload(notifications)
                )
            }
            catch (err) {
                dispatch(ACTIONS.ERROR
                    .withPayload(err)
                );
            }
        })();
    }, []);



    return (
        <Grid gridDefinition={[{ colspan: { default: 9, xl: 10 } }, { colspan: { default: 3, xl: 2 } }]}>
            <SpaceBetween direction="vertical" size="l">
                <Container
                    header={
                        <Header
                            variant="h2"
                            actions={
                                <Select
                                    selectedOption={quarter}
                                    onChange={({ detail }) =>
                                        dispatch(ACTIONS.FIELD
                                            .withFieldName('quarter')
                                            .withPayload(detail.selectedOption)
                                        )
                                    }
                                    options={quarters.map(x => ({ label: x, value: x }))}
                                    selectedAriaLabel="Selected"
                                />
                            }
                        >
                            My overview
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        <AgreementsOverview selectedQuarter={quarter} dispatch={dispatch}></AgreementsOverview>
                        <CalcsOverview selectedQuarter={quarter}></CalcsOverview>
                        <EngineRuntime selectedQuarter={quarter} dispatch={dispatch} batches={engineRuntimeBatches} selectedBatch={engineRuntimeSelectedBatch}></EngineRuntime>
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
            <SpaceBetween direction="vertical" size="l">
                <Header variant="h1" description="Recent updates on items that need your attention.">My Notifications</Header>
                <Container>
                    {isLoading ? <Spinner size="large" variant="normal" /> :
                        <div className="notifications-container">
                            <SpaceBetween direction="vertical" size="xxs">
                                {(notifications).map((x: DashboardNotification) => (
                                    <Alert
                                        key={x.id}
                                        type="warning">
                                        {x.text}
                                        <br /><small>{x.date}</small>
                                    </Alert>))}
                            </SpaceBetween>
                        </div>
                    }
                </Container>
            </SpaceBetween>
        </Grid>
    );

}