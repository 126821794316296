import React, { useContext, useMemo } from 'react';
import 'src/assets/styles/react-table.scss';
import { TPEReactTable } from 'src/components/shared/TPEReactTable';
import { PreValidationContext } from './PreValidationView';
import CONSTANTS from 'src/utils/constants';

export default function PreValidationGrid(props: {loading: boolean}) {
    const { loading } = props;
    const { state } = useContext(PreValidationContext);
    const { category, preValidationResult } = state;

    const getColumnHeaders = () => {
        if (category == CONSTANTS.MEC_PREVALIDATION_CATEGORIES.CLI_STATUS) {
            return Object.values(CONSTANTS.MEC_CLI_STATUS_COLUMN_HEADERS)
        } else if (category == CONSTANTS.MEC_PREVALIDATION_CATEGORIES.COA_CHANGES) {
            return CONSTANTS.MEC_COA_CHANGES_COLUMN_HEADERS
        } else if (category == CONSTANTS.MEC_PREVALIDATION_CATEGORIES.CLI_PTA_DATA) {
            return CONSTANTS.MEC_CLI_PTA_DATA_COLUMN_HEADERS
        } else if (category == CONSTANTS.MEC_PREVALIDATION_CATEGORIES.CLI_CDT_DATA) {
            return CONSTANTS.MEC_CLI_CDT_DATA_COLUMN_HEADERS
        } else if (category == CONSTANTS.MEC_PREVALIDATION_CATEGORIES.INDIRECT_TAX_CHANGES) {
            return CONSTANTS.MEC_INDIRECT_TAX_CHANGES_COLUMN_HEADERS
        } else {
            return []
        }
    }

    const columnHeaders = useMemo(getColumnHeaders, [category]);

    const getColDefs = () => {
        const colDefs: any[] = [];
        columnHeaders.forEach(column => {
            colDefs.push({
                Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                accessor: column.ACCESSOR
            });
        });
        return colDefs;
    }

    const getClassName = () => {
        if (category == CONSTANTS.MEC_PREVALIDATION_CATEGORIES.CLI_STATUS) {
            return 'mecPrevalidationCLIStatusGrid'
        } else if (category == CONSTANTS.MEC_PREVALIDATION_CATEGORIES.COA_CHANGES) {
            return 'mecPrevalidationCOAChangesGrid'
        } else if (category == CONSTANTS.MEC_PREVALIDATION_CATEGORIES.CLI_PTA_DATA) {
            return 'mecPrevalidationCLIPTADataGrid'
        } else if (category == CONSTANTS.MEC_PREVALIDATION_CATEGORIES.CLI_CDT_DATA) {
            return 'mecPrevalidationCLICDTDataGrid'
        } else if (category == CONSTANTS.MEC_PREVALIDATION_CATEGORIES.INDIRECT_TAX_CHANGES) {
            return 'mecPrevalidationIndirectTaxChangesGrid'
        } else {
            return ''
        }
    }

    return (
        <div className="mecGrid">
            {preValidationResult && <TPEReactTable {...{
                data: preValidationResult.records,
                columnDefinitions: getColDefs(),
                className: getClassName() + " mecGrid",
                isSearching: loading,
                editableOptions: null as unknown as any
            }} />}
        </div>
    );
}