import { NavigationState } from 'src/models/navigation/NavigationState';
import { GlobalState } from 'src/services/global/GlobalState';
import { PageHeaderMapping, BreadcrumbItem } from '../models/navigation/PageHeaderMapping';
import DynamicBreadcrumb from '../models/navigation/DynamicBreadcrumb';
import CONSTANTS from 'src/utils/constants';

const dashboard = { text: CONSTANTS.PAGE_NAV.DASHBOARD.TITLE, href: CONSTANTS.PAGE_NAV.DASHBOARD.URL};
const agreements = { text: CONSTANTS.PAGE_NAV.AGREEMENTS.TITLE, href: CONSTANTS.PAGE_NAV.AGREEMENTS.URL };
const agreementDetails = { text: CONSTANTS.PAGE_NAV.AGREEMENT_DETAILS.TITLE, href: CONSTANTS.PAGE_NAV.AGREEMENT_DETAILS.URL };
const reports = { text: CONSTANTS.PAGE_NAV.REPORTS.TITLE, href: CONSTANTS.PAGE_NAV.REPORTS.URL}
const reportDashboard = { text: CONSTANTS.PAGE_NAV.REPORT_DASHBOARD.TITLE, href: CONSTANTS.PAGE_NAV.REPORT_DASHBOARD.URL}
const reportsHistory = { text: CONSTANTS.PAGE_NAV.REPORTS_HISTORY.TITLE, href: CONSTANTS.PAGE_NAV.REPORTS_HISTORY.URL};
const taxAuditRecord = { text: CONSTANTS.PAGE_NAV.TAX_AUDIT_RECORD.TITLE, href: CONSTANTS.PAGE_NAV.TAX_AUDIT_RECORD.URL};
const tpTaxVarianceRecord = { text: CONSTANTS.PAGE_NAV.TP_TAX_VARIANCE_RECORD.TITLE, href: CONSTANTS.PAGE_NAV.TP_TAX_VARIANCE_RECORD.URL};
const taxAuditRecordDetail = { text: CONSTANTS.PAGE_NAV.TAX_AUDIT_RECORD_DETAIL.TITLE, href: CONSTANTS.PAGE_NAV.TAX_AUDIT_RECORD_DETAIL.URL};
const calculations = { text: CONSTANTS.PAGE_NAV.CALCULATIONS.TITLE, href: CONSTANTS.PAGE_NAV.CALCULATIONS.URL };
const searchCalc = { text: CONSTANTS.PAGE_NAV.SEARCH_CALCULATION.TITLE, href: CONSTANTS.PAGE_NAV.SEARCH_CALCULATION.URL };
const calcBuilder = { text: CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.TITLE, href: CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL };
const icrsCalcBuilder = { text: CONSTANTS.PAGE_NAV.ICRS_CALCULATION_BUILDER.TITLE, href: CONSTANTS.PAGE_NAV.ICRS_CALCULATION_BUILDER.URL };
const readOnlyCalc = { text: CONSTANTS.PAGE_NAV.READONLY_CALCULATION.TITLE, href: CONSTANTS.PAGE_NAV.READONLY_CALCULATION.URL };
const linkToTemplate = { text: CONSTANTS.PAGE_NAV.LINK_TO_TEMPLATE.TITLE, href: CONSTANTS.PAGE_NAV.LINK_TO_TEMPLATE.URL };
const cloneCalculation = { text: CONSTANTS.PAGE_NAV.CLONE_CALCULATION.TITLE, href: CONSTANTS.PAGE_NAV.CLONE_CALCULATION.URL };
const customTables = { text: CONSTANTS.PAGE_NAV.CUSTOM_TABLES.TITLE, href: CONSTANTS.PAGE_NAV.CUSTOM_TABLES.URL };
const customDataTableDetails = { text: CONSTANTS.PAGE_NAV.CUSTOM_DATA_TABLE_DETAILS.TITLE, href: CONSTANTS.PAGE_NAV.CUSTOM_DATA_TABLE_DETAILS.URL };
const customCoa = { text: CONSTANTS.PAGE_NAV.CUSTOM_COA.TITLE, href: CONSTANTS.PAGE_NAV.CUSTOM_COA.URL };
const customCoaDetails = { text: CONSTANTS.PAGE_NAV.CUSTOM_COA_DETAILS.TITLE, href: CONSTANTS.PAGE_NAV.CUSTOM_COA_DETAILS.URL };
const tpAllocation = { text: CONSTANTS.PAGE_NAV.TP_ALLOCATION.TITLE, href: CONSTANTS.PAGE_NAV.TP_ALLOCATION.URL };
const tpAllocationStandalone = { text: CONSTANTS.PAGE_NAV.TP_ALLOCATION_STANDALONE.TITLE, href: CONSTANTS.PAGE_NAV.TP_ALLOCATION_STANDALONE.URL };
const tpAllocationLinked = { text: CONSTANTS.PAGE_NAV.TP_ALLOCATION_LINKED.TITLE, href: CONSTANTS.PAGE_NAV.TP_ALLOCATION_LINKED.URL };
const tpAllocationTemplate = { text: CONSTANTS.PAGE_NAV.TP_ALLOCATION_TEMPLATE.TITLE, href: CONSTANTS.PAGE_NAV.TP_ALLOCATION_TEMPLATE.URL };
const preValidationReports = { text: CONSTANTS.PAGE_NAV.PRE_VALIDATION_REPORTS.TITLE, href: CONSTANTS.PAGE_NAV.PRE_VALIDATION_REPORTS.URL};
const monthEndClose = { text: CONSTANTS.PAGE_NAV.MONTH_END_CLOSE.TITLE, href: CONSTANTS.PAGE_NAV.MONTH_END_CLOSE.URL };
const tpTaxReport = {text: CONSTANTS.PAGE_NAV.TP_TAX_REPORT.TITLE, href: CONSTANTS.PAGE_NAV.TP_TAX_REPORT.URL};
const tpTaxLefReport = {text: CONSTANTS.PAGE_NAV.TP_TAX_LEF_REPORT.TITLE, href: CONSTANTS.PAGE_NAV.TP_TAX_LEF_REPORT.URL};
const tpTaxCwbReport = {text: CONSTANTS.PAGE_NAV.TP_TAX_CWB_REPORT.TITLE, href: CONSTANTS.PAGE_NAV.TP_TAX_CWB_REPORT.URL};
const bulkActions = {text: CONSTANTS.PAGE_NAV.BULK_ACTIONS.TITLE, href: CONSTANTS.PAGE_NAV.BULK_ACTIONS.URL};

const pathItems = [dashboard,
    agreements,
    reports,
    reportDashboard,
    reportsHistory,
    taxAuditRecord,
    taxAuditRecordDetail,
    agreementDetails,
    calculations,
    searchCalc,
    calcBuilder,
    readOnlyCalc,
    linkToTemplate,
    cloneCalculation,
    customTables,
    customDataTableDetails,
    customCoa,
    customCoaDetails,
    tpAllocation,
    tpAllocationStandalone,
    tpAllocationLinked,
    tpAllocationTemplate,
    preValidationReports,
    monthEndClose,
    tpTaxVarianceRecord,
    tpTaxCwbReport,
    tpTaxLefReport,
    tpTaxReport,
    bulkActions
];

const appPageHeaderMapping: Array<PageHeaderMapping> =
    [
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.DASHBOARD.URL,
            CONSTANTS.PAGE_NAV.DASHBOARD.TITLE,
            CONSTANTS.PAGE_NAV.DASHBOARD.DESCRIPTION,
            [dashboard]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.AGREEMENTS.URL,
            CONSTANTS.PAGE_NAV.AGREEMENTS.TITLE,
            CONSTANTS.PAGE_NAV.AGREEMENTS.DESCRIPTION,
            [dashboard, agreements]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.AGREEMENT_DETAILS.URL,
            CONSTANTS.PAGE_NAV.AGREEMENT_DETAILS.TITLE,
            CONSTANTS.PAGE_NAV.AGREEMENT_DETAILS.DESCRIPTION,
            [dashboard, agreements, agreementDetails]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.REPORTS.URL,
            CONSTANTS.PAGE_NAV.REPORTS.TITLE,
            CONSTANTS.PAGE_NAV.REPORTS.DESCRIPTION,
            [dashboard, reports]
        ),
        new PageHeaderMapping(
             CONSTANTS.PAGE_NAV.REPORT_DASHBOARD.URL,
             CONSTANTS.PAGE_NAV.REPORT_DASHBOARD.TITLE,
             CONSTANTS.PAGE_NAV.REPORT_DASHBOARD.DESCRIPTION,
             [dashboard, reportDashboard]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.REPORTS_HISTORY.URL,
            CONSTANTS.PAGE_NAV.REPORTS_HISTORY.TITLE,
            CONSTANTS.PAGE_NAV.REPORTS_HISTORY.DESCRIPTION,
            [dashboard, reports, reportsHistory]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.TAX_AUDIT_RECORD.URL,
            CONSTANTS.PAGE_NAV.TAX_AUDIT_RECORD.TITLE,
            CONSTANTS.PAGE_NAV.TAX_AUDIT_RECORD.DESCRIPTION,
            [dashboard, reports, taxAuditRecord]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.TP_TAX_VARIANCE_RECORD.URL,
            CONSTANTS.PAGE_NAV.TP_TAX_VARIANCE_RECORD.TITLE,
            CONSTANTS.PAGE_NAV.TP_TAX_VARIANCE_RECORD.DESCRIPTION,
            [dashboard, reports, tpTaxVarianceRecord],
            CONSTANTS.PAGE_NAV.TP_TAX_VARIANCE_RECORD.HEADER_SIDEBAR_KEY
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.TP_TAX_LEF_REPORT.URL,
            CONSTANTS.PAGE_NAV.TP_TAX_LEF_REPORT.TITLE,
            CONSTANTS.PAGE_NAV.TP_TAX_LEF_REPORT.DESCRIPTION,
            [dashboard, reports, tpTaxLefReport]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.TP_TAX_CWB_REPORT.URL,
            CONSTANTS.PAGE_NAV.TP_TAX_CWB_REPORT.TITLE,
            CONSTANTS.PAGE_NAV.TP_TAX_CWB_REPORT.DESCRIPTION,
            [dashboard, reports, tpTaxCwbReport]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.TAX_AUDIT_RECORD_DETAIL.URL,
            CONSTANTS.PAGE_NAV.TAX_AUDIT_RECORD_DETAIL.TITLE,
            CONSTANTS.PAGE_NAV.TAX_AUDIT_RECORD_DETAIL.DESCRIPTION,
            [dashboard, reports, taxAuditRecord, taxAuditRecordDetail]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.CALCULATIONS.URL,
            CONSTANTS.PAGE_NAV.CALCULATIONS.TITLE,
            CONSTANTS.PAGE_NAV.CALCULATIONS.DESCRIPTION,
            [dashboard, calculations]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.SEARCH_CALCULATION.URL,
            CONSTANTS.PAGE_NAV.SEARCH_CALCULATION.TITLE,
            CONSTANTS.PAGE_NAV.SEARCH_CALCULATION.DESCRIPTION,
            [dashboard, calculations, searchCalc]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL,
            CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.TITLE,
            CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.DESCRIPTION,
            [dashboard, calculations, calcBuilder]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.ICRS_CALCULATION_BUILDER.URL,
            CONSTANTS.PAGE_NAV.ICRS_CALCULATION_BUILDER.TITLE,
            CONSTANTS.PAGE_NAV.ICRS_CALCULATION_BUILDER.DESCRIPTION,
            [dashboard, calculations, icrsCalcBuilder]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.READONLY_CALCULATION.URL,
            CONSTANTS.PAGE_NAV.READONLY_CALCULATION.TITLE,
            CONSTANTS.PAGE_NAV.READONLY_CALCULATION.DESCRIPTION,
            [{ text: '', href: '' }]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.LINK_TO_TEMPLATE.URL,
            CONSTANTS.PAGE_NAV.LINK_TO_TEMPLATE.TITLE,
            CONSTANTS.PAGE_NAV.LINK_TO_TEMPLATE.DESCRIPTION,
            [dashboard, calculations, linkToTemplate]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.CLONE_CALCULATION.URL,
            CONSTANTS.PAGE_NAV.CLONE_CALCULATION.TITLE,
            CONSTANTS.PAGE_NAV.CLONE_CALCULATION.DESCRIPTION,
            [dashboard, calculations, cloneCalculation]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.CUSTOM_TABLES.URL,
            CONSTANTS.PAGE_NAV.CUSTOM_TABLES.TITLE,
            CONSTANTS.PAGE_NAV.CUSTOM_TABLES.DESCRIPTION,
            [dashboard, calculations, customTables]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.CUSTOM_DATA_TABLE_DETAILS.URL,
            CONSTANTS.PAGE_NAV.CUSTOM_DATA_TABLE_DETAILS.TITLE,
            CONSTANTS.PAGE_NAV.CUSTOM_DATA_TABLE_DETAILS.DESCRIPTION,
            [dashboard, calculations, customTables, customDataTableDetails]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.CUSTOM_COA.URL,
            CONSTANTS.PAGE_NAV.CUSTOM_COA.TITLE,
            CONSTANTS.PAGE_NAV.CUSTOM_COA.DESCRIPTION,
            [dashboard, calculations, customCoa]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.CUSTOM_COA_DETAILS.URL,
            CONSTANTS.PAGE_NAV.CUSTOM_COA_DETAILS.TITLE,
            CONSTANTS.PAGE_NAV.CUSTOM_COA_DETAILS.DESCRIPTION,
            [dashboard, calculations, customCoa, customCoaDetails]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.TP_ALLOCATION.URL,
            CONSTANTS.PAGE_NAV.TP_ALLOCATION.TITLE,
            CONSTANTS.PAGE_NAV.TP_ALLOCATION.DESCRIPTION,
            [dashboard, calculations, tpAllocation]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.TP_ALLOCATION_STANDALONE.URL,
            CONSTANTS.PAGE_NAV.TP_ALLOCATION_STANDALONE.TITLE,
            CONSTANTS.PAGE_NAV.TP_ALLOCATION_STANDALONE.DESCRIPTION,
            [dashboard, calculations, tpAllocation, tpAllocationStandalone]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.TP_ALLOCATION_LINKED.URL,
            CONSTANTS.PAGE_NAV.TP_ALLOCATION_LINKED.TITLE,
            CONSTANTS.PAGE_NAV.TP_ALLOCATION_LINKED.DESCRIPTION,
            [dashboard, calculations, tpAllocation, tpAllocationLinked]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.TP_ALLOCATION_TEMPLATE.URL,
            CONSTANTS.PAGE_NAV.TP_ALLOCATION_TEMPLATE.TITLE,
            CONSTANTS.PAGE_NAV.TP_ALLOCATION_TEMPLATE.DESCRIPTION,
            [dashboard, calculations, tpAllocation, tpAllocationTemplate]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.PRE_VALIDATION_REPORTS.URL,
            CONSTANTS.PAGE_NAV.PRE_VALIDATION_REPORTS.TITLE,
            CONSTANTS.PAGE_NAV.PRE_VALIDATION_REPORTS.DESCRIPTION,
            [dashboard, reports, preValidationReports]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.MONTH_END_CLOSE.URL,
            CONSTANTS.PAGE_NAV.MONTH_END_CLOSE.TITLE,
            CONSTANTS.PAGE_NAV.MONTH_END_CLOSE.DESCRIPTION,
            [dashboard, monthEndClose]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.TP_TAX_REPORT.URL,
            CONSTANTS.PAGE_NAV.TP_TAX_REPORT.TITLE,
            CONSTANTS.PAGE_NAV.TP_TAX_REPORT.DESCRIPTION,
            [dashboard, reports, tpTaxReport]
        ),
        new PageHeaderMapping(
            CONSTANTS.PAGE_NAV.BULK_ACTIONS.URL,
            CONSTANTS.PAGE_NAV.BULK_ACTIONS.TITLE,
            CONSTANTS.PAGE_NAV.BULK_ACTIONS.DESCRIPTION,
            [dashboard, calculations, bulkActions]
        )
    ];

export const getPageHeaderMapping = (path: string) => {
    let pageHeaderMapping: PageHeaderMapping | undefined = appPageHeaderMapping.find(x => x.pathName === path);
    if (pageHeaderMapping == undefined) {
        const pathWithoutURLParam = path.substring(0, path.lastIndexOf('/'));
        pageHeaderMapping = appPageHeaderMapping.find(x => x.pathName === pathWithoutURLParam);
    }
    return pageHeaderMapping || new PageHeaderMapping('', '', '', []);
}

const getPathName = (path: string) => {
    let pathItem = pathItems.find(x => x.href === path);
    if (pathItem == undefined) {
        const pathWithoutURLParam = path.substring(0, path.lastIndexOf('/'));
        pathItem = pathItems.find(x => x.href == pathWithoutURLParam)
    }
    return pathItem ? pathItem.text : '';
}

// Update the breadcrumbs list with the next path
const updateBreadcrumbs = (nextPath: string, breadcrumbs: BreadcrumbItem[]) => {
    const index = breadcrumbs.findIndex(x => x.href === nextPath);

    if (index > -1) { // If a breadcrumb is clicked
        const breadcrumbsCopy = [...breadcrumbs];
        return breadcrumbsCopy.slice(0, index + 1);
    } else { // All other cases
        return [...breadcrumbs, { href: nextPath, text: getPathName(nextPath) }];
    }
}

// Function to be used for all naviagtion in the application
export const navigateTo = (history: any, currentPath: string, nextPath: string, navigationState: NavigationState | undefined, globalState: GlobalState, fromLeftNav?: boolean) => {
    if (history == undefined) {
        window.location.href = nextPath;
        return;
    }
    const navigationStateCopy = navigationState == undefined ? {} : {...navigationState};

    if (navigationState == undefined && currentPath != '/') {
        // User copied a link directly and was shown default breadcrumb
        // Now they are navigating from that page, so build a navigation path
        navigationStateCopy.breadcrumbs = constructBreadcrumbs(currentPath, getPageHeaderMapping(currentPath).defaultBreadcrumbs, globalState?.dynamicBreadcrumb);
    }

    if (nextPath == '/') { // Home page
        history.push(nextPath, { breadcrumbs: getPageHeaderMapping(nextPath).defaultBreadcrumbs });
    } else if (nextPath.lastIndexOf('/') == 0) { // Directly clicking on left hand nav or base pages
        if (fromLeftNav) { // Do not pass other state variables when clicking on left nav icon (this is for showCalculationsWorklists)
            history.push(nextPath, {
                breadcrumbs: getPageHeaderMapping(nextPath).defaultBreadcrumbs
            });
        } else { // Pass other state variables also when clicking on the breadcrumb (this is for showCalculationsWorklists)
            const passState: NavigationState = constructPassState(nextPath, navigationStateCopy || {}, globalState);
            history.push(nextPath, {
                ...passState,
                breadcrumbs: getPageHeaderMapping(nextPath).defaultBreadcrumbs
            });
        }
    } else { // Other cases
        const passState: NavigationState = constructPassState(nextPath, navigationStateCopy, globalState);
        history.push(nextPath, {
            ...passState,
            breadcrumbs: updateBreadcrumbs(nextPath, passState.breadcrumbs || []),
        })
    }
}

const constructPassState = (path: string, navigationState: NavigationState, globalState: GlobalState) => {
    if (globalState == undefined) {
        return navigationState == undefined ? {} : navigationState;
    }

    const passState = {
        ... (navigationState || {}),
        breadcrumbs: constructBreadcrumbs(path, navigationState?.breadcrumbs || [], globalState.dynamicBreadcrumb)
    };

    if (globalState.selectedWorklistsTab != undefined) {
        passState.selectedWorklistsTab = globalState.selectedWorklistsTab;
    }
    return passState;
}

// Construct dynamic breadcrumb
export const constructBreadcrumbs = (path: string, breadcrumbs: BreadcrumbItem[], dynamicBreadcrumb: DynamicBreadcrumb | undefined) => {
    const breadcrumbsCopy = breadcrumbs ? [...breadcrumbs] : getPageHeaderMapping(path).defaultBreadcrumbs;

    // Update the last path if getting default breadcrumbs
    if (breadcrumbs == undefined && breadcrumbsCopy[breadcrumbsCopy.length - 1]) {
        breadcrumbsCopy[breadcrumbsCopy.length - 1].href = path;
    }

    if (dynamicBreadcrumb != undefined
            && dynamicBreadcrumb.breadCrumb != ''
            && breadcrumbsCopy.length > 0
            && dynamicBreadcrumb.path == breadcrumbsCopy[breadcrumbsCopy.length - 1].href) {

        const lastBreadcrumb: BreadcrumbItem = breadcrumbsCopy.pop() || { href: '', text: '' };

        breadcrumbsCopy.push({
            href: lastBreadcrumb.href,
            text: dynamicBreadcrumb.breadCrumb
        });
    }
    return breadcrumbsCopy;
}