import React from 'react';
import { Box, Container, Grid, Select } from "@amzn/awsui-components-react/polaris";
import { ACTIONS } from 'src/services/dashboard/DashboardReducer';
import image from 'src/images/engine_runtime.png';
import { DashboardAction } from 'src/models/dashboard';

type ComponentProps = {
    selectedQuarter: any
    dispatch: React.Dispatch<DashboardAction>,
    batches: Array<string>,
    selectedBatch: any
}

export default function EngineRuntime(props: ComponentProps) {
    const { dispatch, batches, selectedBatch } = props;
    return (
        <Container>
            <Grid gridDefinition={[
                { colspan: { default: 10, xl: 11, s: 10, xxs: 8 } },
                { colspan: { default: 2, xl: 1, s: 2, xxs: 4 } },
            ]}>
                <Box color="text-body-secondary">TP Engine Runtime (average)</Box>
                <Box textAlign="right">
                    <Select
                        selectedOption={selectedBatch}
                        onChange={({ detail }) =>
                            dispatch(ACTIONS.SET_ENGINE_RUNTIME_BATCH
                                .withPayload(detail.selectedOption)
                            )
                        }
                        options={batches.map((x: any) => ({ label: x, value: x }))}
                        selectedAriaLabel="Selected"
                    />
                </Box>
            </Grid>
            <Box textAlign="center" className="chart-container">
                <img src={image} />
            </Box>
        </Container>
    );
}