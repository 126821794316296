import { REPORT_DETAILS } from "./ReportDefinitionConstants";

export const TP_TAX_REPORT_DETAILS = [
    {
        name: REPORT_DETAILS.TP_TAX_VARIANCE,
        description: 'This report provides a consolidated view across all CLIs that provides for targeted versus actual margins earned, identifying potential execution errors or variances, and potential root cause of variances for those that may require further investigation.'
    },
    {
        name: REPORT_DETAILS.TP_TAX,
        description: 'This report provides a comprehensive TP Profit & Loss (P&L) view and PLI, with account drill-down capabilities, for individual CLIs executed within ATP.'
    },
    {
        name: REPORT_DETAILS.TP_TAX_CWB_REPORT,
        description: 'This report provides a comprehensive TP Profit & Loss (P&L) view and PLI, with account drill-down capabilities, for all CLIs within a Workbook / Close Manager Task that are executed within ATP.'
    },
    {
        name: REPORT_DETAILS.TP_TAX_LEF_REPORT,
        description: 'This report provides a comprehensive TP Profit & Loss (P&L) view and PLI for all CLIs executed in ATP under a given legal entity. This report contains total LE accout balances, functional segment aggregations, realted allocation ratios, account drill-down capabilities, and validations.'
    },
]

export const TP_ACCOUNTING_REPORT_DETAILS = [
    {
        name: REPORT_DETAILS.CALCULATIONS,
        description: "This report contains calcuation configuration steps for a CLI, including all data sources and the realted series of mathematical operations used to determine current month YTD charge."
    },
    {
        name: REPORT_DETAILS.DATASOURCES,
        description: "This report contains all data sources for all CLI's, with the respective values, for the current month-end-close (MEC)."
    },
    {
        name: REPORT_DETAILS.CUSTOM_COA,
        description: 'This report contains custom chart of accounts (COA) (i.e. non-FRAMES rollups) used in the current month-end-close (MEC).'
    },

    {
        name: REPORT_DETAILS.JOURNAL_DETAILS,
        description: "This report contains all journal entry lines created for all CLIs executed within ATP that are posted to the GL for the current month-end-close (MEC)."
    },
    {
        name: REPORT_DETAILS.TP_TRENDING,
        description: "This report contains period-over-period difference for the value of a CLI charge over the previous 13 months."
    },
    {
        name: REPORT_DETAILS.TAX_AGREEMENT_DETAILS,
        description: "This report summarizes the number of active CLI that will be processed during the month-end-close (MEC). It provides insight around new or terminated CLIs."
    },
    {
        name: REPORT_DETAILS.COA_CHANGE_MANAGEMENT,
        description: "This report provides a summary view of chart of accounts (COA) changes (i.e., changes made to the 7 segments in FRAMES) during the month-end-close (MEC)."
    },
    {
        name: REPORT_DETAILS.PRE_VALIDATION_REPORTS,
        description: "UI-based view of changes by CLI for: (i) indirect tax rates, (ii) chart of accounts (COA), (iii) path-to-automation data, and (iv) custom data table (CDT) changes for the current month-end-close (MEC)."
    },
    {
        name: REPORT_DETAILS.ALLOCATION_TRENDING,
        description: "This report presents a 13-month trend for the allocation rate used to calculate allocations for the listed CLI."
    },
    {
        name: REPORT_DETAILS.FORMULA_SET,
        description: "This report provides visibility into changes made to CLI calculation configurations and templates by step, providing related metadata (e.g., expression, user, and date changed)."
    },
    {
        name: REPORT_DETAILS.CALCULATION_CHANGE,
        description: "This report contains audit trail to identify what was changed, when, and by whom for a given CLI."
    },
    {
        name: REPORT_DETAILS.CUSTOM_DATA_AUTO_INSERT,
        description: "This report provides visibility into values added to a custom data table (CDT) by the ATP system. This action is triggered due to data not having been added/updated in the CDT by the deadline for the current month-end-close (MEC)."
    }
]

export const INDIRECT_TAX_REPORT_DETAILS = [
    {
        name: REPORT_DETAILS.TAX_AUDIT_RECORD,
        description: "Provides details relavent to indirect tax reporting (e.g., transaction, calcuation, report, entity, agreement, tax product, and invoice details)."
    }
]